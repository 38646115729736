import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/styles.css';
import { AiOutlineLoading3Quarters  } from 'react-icons/ai';
import { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import Logo from 'assets/images/lemery-logo.png';

const Element = lazy(() => import('./pages/Elements'));
const UnderConstruction = lazy(() => import('./pages/UnderConstruction'));

// Auth Folder
const LoginPage = lazy(() => import('./pages/Auth/LoginPage'));
const AdminLoginPage = lazy(() => import('./pages/Auth/AdminLoginPage'));
const ProviderLoginPage = lazy(() => import('./pages/Auth/ProviderLoginPage'));
const TouristRegisterPage = lazy(() => import('./pages/Auth/TouristRegisterPage'));
const ProviderRegisterPage = lazy(() => import('./pages/Auth/ProviderRegisterPage'));
const ForgotPasswordPage = lazy(() => import('./pages/Auth/ForgotPassword'));

// Shared Folder
const LandingPage = lazy(() => import('./pages/Shared/LandingPage'));
const CategoryPlacePage = lazy(() => import('./pages/Shared/CategoryPlacePage'));
const WeatherPage = lazy(() => import('./pages/Shared/WeatherPage'));
const ContactsPage = lazy(() => import('./pages/Shared/ContactsPage'));
const ProfilePage = lazy(() => import('./pages/Shared/ProfilePage'));

// Tourist Folder
const TouristSpotsPage = lazy(() => import('./pages/Tourist/TouristSpotsPage'));
const MapsPage = lazy(() => import('./pages/Tourist/MapsPage'));
const PersonalInformationPage = lazy(() => import('./pages/Tourist/PersonalInfoPage'));

// Provider Folder
const ProviderPersonalInformationPage = lazy(() => import('./pages/Provider/PersonalInfoPage'));
const ProviderDashboardPage = lazy(() => import('./pages/Provider/DashboardPage'));
const ProviderVisitorsPage = lazy(() => import('./pages/Provider/VisitorsPage'));
const ProviderFeedbacksPage = lazy(() => import('./pages/Provider/FeedbacksPage'));

// Admin Folder
const AdminDashboardPage = lazy(() => import('./pages/Admin/DashboardPage'));
const AdminDataProvidersPage = lazy(() => import('./pages/Admin/DataProvidersPage'));
const AdminContactsPage = lazy(() => import('./pages/Admin/ContactsPage'));
const AdminEventsPage = lazy(() => import('./pages/Admin/EventsPage'));
const AdminFeedbacksPage = lazy(() => import('./pages/Admin/FeedbacksPage'));
const AdminProviderVisitorsPage = lazy(() => import('./pages/Admin/ProviderVisitorsPage'));
const AdminUsersPage = lazy(() => import('./pages/Admin/AdminsPage'));

const App = () => {
    return (
        <Router>
            <Suspense fallback={
                <div className='global-loading text-primary'>
                    {/* <AiOutlineLoading3Quarters size="50px" className='loading' />  */}
                    <img src={Logo} alt='Municipality of Lemery' width="130px" className='fade-loading' />
                </div>
            }>
                <Routes>
                    {/* <Route path="/" exact element={<UnderConstruction />} /> */}

                    <Route path="/element" exact element={<Element />} />
                    
                    <Route path="/" exact element={<LandingPage />} />
                    <Route path="/lemery/:categoryPlace/:categoryPlaceId" exact element={<CategoryPlacePage />} />
                    <Route path="/lemery/weather-update" exact element={<WeatherPage />} />

                    {/* Auth */}
                    <Route path="/login" exact element={<LoginPage />} />
                    <Route path="/admin/login" exact element={<AdminLoginPage />} />
                    <Route path="/provider/login" exact element={<ProviderLoginPage />} />
                    <Route path="/register" exact element={<TouristRegisterPage />} />
                    <Route path="/provider/register" exact element={<ProviderRegisterPage />} />
                    <Route path="/forgot/password" exact element={<ForgotPasswordPage />} />

                    <Route path="/profile/:id" exact element={<ProfilePage />} />

                    {/* Tourist Pages */}
                    <Route path="/personal-information" exact element={<PersonalInformationPage />} />

                    <Route path="/lemery/:categoryParam" exact element={<TouristSpotsPage />} />
                    <Route path="/lemery/direction-map" exact element={<MapsPage />} />
                    <Route path="/lemery/contacts" exact element={<ContactsPage />} />

                    {/* Provider Pages */}
                    <Route path="/provider/personal-information" exact element={<ProviderPersonalInformationPage />} />
                    <Route path="/provider/dashboard" exact element={<ProviderDashboardPage />} />
                    <Route path="/provider/visitors" exact element={<ProviderVisitorsPage />} />
                    <Route path="/provider/feedbacks" exact element={<ProviderFeedbacksPage />} />

                    {/* Admin Pages */}
                    <Route path="/admin/dashboard" exact element={<AdminDashboardPage />} />
                    <Route path="/admin/data-providers" exact element={<AdminDataProvidersPage />} />
                    <Route path="/admin/contacts" exact element={<AdminContactsPage />} />
                    <Route path="/admin/events" exact element={<AdminEventsPage />} />
                    <Route path="/admin/feedbacks" exact element={<AdminFeedbacksPage />} />
                    <Route path="/admin/provider/:providerId/visitors" exact element={<AdminProviderVisitorsPage />} />
                    <Route path="/admin/users" exact element={<AdminUsersPage />} />
                </Routes>
            </Suspense>
            <Toaster
              position="top-right"
              reverseOrder={false}
            /> {/* Declare the toast alert globally */}
            <ToastContainer />
        </Router>
    );
};

export default App;